//Libraries
import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

//Context
import { FontSizeProvider } from '../context/fontSizeContext'

//Types
import { FormsAndDocumentsTypes } from './z_page-types'

//Components
import Header from '../components/header/header'
import Layout from '../components/layout/layout'
import Footer from '../components/footer/footer'
import FormsList from '../components/free/formsList'
import PageWelcome from '../components/header/pageWelcome'

const FormsAndDocuments: FunctionComponent<FormsAndDocumentsTypes> = ({
  data,
}) => (
  <FontSizeProvider>
      <Helmet htmlAttributes={{lang: 'en'}}>
        <meta charSet="utf-8" />
        <title>OOA - Forms and Documents</title>
        <link rel="canonical" href="https://ooa.csi.edu/forms-and-documents/" />
        <meta
          name="description"
          content="See any important forms the Office on Aging has."
        />
      </Helmet>

    <Header />

    <Layout>
      <PageWelcome
        img={data.markdownRemark.frontmatter.img}
        title={data.markdownRemark.frontmatter.title}
        imgAltText="Forms and documents banner image"
      />

      <FormsList />
    </Layout>

    <Footer />
  </FontSizeProvider>
)

export default FormsAndDocuments

export const formsAndDocumentsQuery = graphql`
  query formsAndDocuments {
    markdownRemark(
      frontmatter: { template_key: { eq: "forms-and-documents-page" } }
    ) {
      frontmatter {
        title
        img {
          childImageSharp {
            fluid(maxWidth: 1700, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
