//Libraries
import React, { FunctionComponent, useEffect, useState } from 'react'
import axios from 'axios'
import cs from 'classnames'
import { sortBy } from 'lodash'

//Types
import { fileTypes, rawDataTypes } from '../z_components-types'

//Styles
import styles from './css/formsList.module.scss'

const SORTS = {
  TITLE: (files: fileTypes) => sortBy(files, 'title'),
  TITLE_REV: (files: fileTypes) => sortBy(files, 'title').reverse(),
  TYPE: (files: fileTypes) => sortBy(files, 'icon'),
  TYPE_REV: (files: fileTypes) => sortBy(files, 'icon').reverse(),
}

const FormsList: FunctionComponent<{}> = () => {
  const [files, setFiles] = useState<{
    id: string
    parentId: string
    title: string
    link: string
    icon: string
    type: string
  }[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [sort, setSort] = useState('TYPE')
  const [currentFolder, setCurrentFolder] = useState<string[]>([])

  useEffect(() => {
    async function apiCall() {
      await axios
        .get(`/.netlify/functions/getGoogleDriveFiles`)
        .then(response => {
          response.data.items.forEach((file: rawDataTypes) => {
            //Set current folder to root folder
            file.parents.length === 0 && setCurrentFolder([file.id])

            //Collects all files in array
            file.parents.length &&
              setFiles(files => [
                ...files,
                {
                  id: file.id,
                  parentId: file.parents[0].id,
                  title: file.mimeType.includes('folder')
                    ? file.title
                    : file.title.substr(0, file.title.lastIndexOf('.')),
                  link: file.alternateLink,
                  icon: file.iconLink,
                  type: file.mimeType.substr(
                    file.mimeType.lastIndexOf('.') + 1
                  ),
                },
              ])
          })
        })
        .catch(err => console.log(err))
      setIsLoading(false)
    }

    apiCall()
  }, [])

  const sortFunction = SORTS[sort]
  const sortedFiles = sortFunction(files)

  //Sorts list by chosen sort type
  const handleSort: (sortType: string) => void = sortType => {
    if (sortType === 'Type') {
      setSort(sort => (sort === 'TYPE' ? 'TYPE_REV' : 'TYPE'))
    } else if (sortType === 'Title') {
      setSort(sort => (sort === 'TITLE' ? 'TITLE_REV' : 'TITLE'))
    }
  }

  //Opens folder
  const drillIntoFolder: (file: fileTypes) => void = file => {
    if (file.type === 'folder') {
      setCurrentFolder([file.id, ...currentFolder])
    } else {
      document.location.href = file.link
    }
  }

  //Goes to previous folder
  const backOutOfFolder: () => void = () => {
    setCurrentFolder(currentFolder.slice(1))
  }

  return (
    <section className={styles.documents}>
      {isLoading ? (
        <table className={cs('table', styles.table)}>
          <thead>
            <tr>
              <th scope="col" className={styles.typeHeader}>
                Type
              </th>
              <th scope="col">Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div className={styles.shineShort}></div>
              </td>
              <td>
                <div className={styles.shineLong}></div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.shineShort}></div>
              </td>
              <td>
                <div className={styles.shineLong}></div>
              </td>
            </tr>
            <tr>
              <td>
                <div className={styles.shineShort}></div>
              </td>
              <td>
                <div className={styles.shineLong}></div>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <table className={cs('table table-hover', styles.table)}>
          <thead>
            <tr>
              <th
                scope="col"
                className={cs(
                  styles.typeHeader,
                  sort === 'TYPE'
                    ? styles.asc
                    : sort === 'TYPE_REV' && styles.dec
                )}
                onClick={() => handleSort('Type')}
              >
                Type
              </th>
              <th
                scope="col"
                className={cs(
                  styles.nameHeader,
                  sort === 'TITLE'
                    ? styles.asc
                    : sort === 'TITLE_REV'
                    ? styles.dec
                    : ''
                )}
                onClick={() => handleSort('Title')}
              >
                Name
              </th>
            </tr>
          </thead>
          <tbody>
            {currentFolder.length > 1 && (
              <tr onClick={backOutOfFolder} className={styles.file}>
                <td>
                  <i className="ri-arrow-left-circle-line"></i>
                </td>
                <td>Back</td>
              </tr>
            )}
            {sortedFiles.length > 1 ? (
              sortedFiles.map(
                (file: fileTypes) =>
                  file.parentId === currentFolder[0] && (
                    <tr
                      className={styles.file}
                      key={file.id}
                      onClick={() => drillIntoFolder(file)}
                    >
                      <td>
                        <img src={file.icon} />
                      </td>
                      <td>{file.title}</td>
                    </tr>
                  )
              )
            ) : (
              <tr>
                <td>
                  <i className="ri-file-forbid-line"></i>
                </td>
                <td>Sorry! We couldn't find any documents at this time</td>
              </tr>
            )}
          </tbody>
        </table>
      )}
    </section>
  )
}

export default FormsList
